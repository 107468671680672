import React from 'react';
import { Typography } from 'antd';
import { useStaticQuery, graphql } from 'gatsby';

import { Metadata } from '../components';
import StandardLayout from '../layouts/standard';

const SocialJusticePage = () => {

	const { markdownRemark } = useStaticQuery(
		graphql`
			query {
        		markdownRemark(fileAbsolutePath: {regex: "/content\/social-justice/"}) {
					frontmatter {
			  			title
					}
					html
					htmlAst
		  		}
      		}
    	`
	);

	return (
		<StandardLayout>
			<Metadata title={ markdownRemark.frontmatter.title } />
			<Typography.Title level={2}>{markdownRemark.frontmatter.title}</Typography.Title>
			<div dangerouslySetInnerHTML={{ __html: markdownRemark.html }} />
		</StandardLayout>

	)

};

export default SocialJusticePage;
